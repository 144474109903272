import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostMeta from "../components/post-meta";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout>
        <SEO title="All posts" />
        {posts
          .filter(({ node }) => node.frontmatter.layout === "post")
          .map(({ node }) => {
            const title = node.frontmatter.title;
            return (
              <article className="post" key={node.fields.slug}>
                <h2 className="posttitle">
                  <Link to={node.fields.slug}>{title}</Link>
                </h2>
                <PostMeta
                  date={node.frontmatter.date}
                  category={node.frontmatter.categories}
                />
                <div
                  className="p-padding postcontent entry-content"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                ></div>
                <p>
                  <Link to={node.fields.slug}>Read More</Link>
                </p>
              </article>
            );
          })}
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            categories
            layout
          }
        }
      }
    }
  }
`;
